<template>
  <main>
    <template v-if="typedEntity?.entity">
      <NodePage
        v-if="typedEntity.bundle === 'page'"
        v-bind="typedEntity.entity"
      />
      <LazyNodeFilterPage
        v-else-if="typedEntity.bundle === 'filter_page'"
        v-bind="typedEntity.entity"
      />
      <NodeTopicPage
        v-else-if="typedEntity.bundle === 'topic'"
        v-bind="typedEntity.entity"
      />
      <NodeOrganizationPage
        v-else-if="typedEntity.bundle === 'organization_page'"
        v-bind="typedEntity.entity"
      />
      <LazyNodeGarbageMaterial
        v-else-if="typedEntity.bundle === 'garbage_material'"
        v-bind="typedEntity.entity"
      />
    </template>
  </main>
</template>

<script lang="ts" setup>
definePageMeta({
  name: 'drupal-route',
})

defineOptions({
  name: 'PageSlug',
})

const { typedEntity } = await useDrupalRouteQuery()

await renderPageDependencies()
</script>
